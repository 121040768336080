@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

/* Base Styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

html, body {
  overflow-x: hidden;
}

body {
  position: relative;
  font-family: "Source Sans Pro", sans-serif;
}

a {
  text-decoration: none;
  font-weight: 400;
  font-size: 1.8rem;
}

h1, h2, p {
  margin: 1.6rem 0;
}

h1 {
  font-weight: 800;
  font-size: 4rem;
}

h2 {
  font-weight: 400;
  font-size: 2.4rem;
}

p {
  font-weight: 400;
  font-size: 1.8rem;
}

img {
  width: 100%;
  height: auto;
}

.row {
  display: flex;
  justify-content: space-between;
  width: 80%;
  align-items: center;
  margin: auto;
}

section {
  padding: 80px 0;
}


/* Nav Section */

header {
  width: 100%;
  height: 75px;
}

nav {
  height: 75px;
  width: 100%;
  padding: 1% 5%;
  display: flex;
  justify-content: space-between;
  background-color: whitesmoke;
  position: fixed;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
  z-index: 999;
}

nav li {
  display: inline-block;
  margin-left: 2.5rem;
  line-height: 55px;
}

nav li a {
  font-size: 2rem;
  color: #354347;
}

nav li a:hover {
  color: #1E3340;
  transition: .3s ease-in-out;
}

.logo a {
  color: grey;
  letter-spacing: 4px;
}

.logo h1 {
  margin: 0;
  font-size: 3.6rem;
}

.logo2 {
  font-weight: 300;
  font-size: 3.6rem;
}

.hamburger {
  display: none;
}

.closeIcon {
  display: none;
}

/* Hero Section */

.hero {
  padding-left: 10%;
  padding-right: 10%;
  background-color: #edede9;
}

.hero h2 {
  color: grey;
}

.hero h1 {
  color: #A67063;
}

.headShot {
  border-radius: 50%;
  width: 40rem;

}

.heroLeft {
  margin-right: 25px;
}

.heroLeft a img {
  height: 36px;
  width: 36px;
  margin: 5px;

}

.heroRight {
  margin-left: 25px;
}

.heroLeft p a {
  color: #1E3340;
}



.resume {
  font-size: 30px;
  font-weight: bold;
  padding: 12px;
  color: #EDEDE9;
  border-radius: 4px;
  border: none;
  background-color: #1E3340;
  cursor: pointer;
  display: block;
  width: 70%;
  margin-top: 6px;
}

.resume:hover {
  scale: 1.01;
  background-color: #354347;
}

.view-projects {
  font-size: 30px;
  font-weight: bold;
  color: #EDEDE9;
}

.heroLeft p a:hover {
  color: #354347;
  transition: .3s ease-in-out;
}

/* Projects Section */
.row1{
  align-items: stretch;
}
.projectsTitle {
  text-align: center;
  color: #1E3340;
}

.projContainer {
  padding: 1rem;
  border: 1px solid lightgrey;
  margin: 1rem;
  cursor: pointer;
  width: 30%;
  height: auto;
  border-radius: 4px;
}

.projContainer:hover {
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  transition: box-shadow .2s ease-in-out;
  background-color: #e3d5ca;
  transition: background-color .2s ease-in-out;
  transform: scale(1.02);
  transition: transform .2s ease-in-out;
}

.projContainer img {
  border: 1px solid lightgrey;
  border-radius: 4px;
}

.showRow2 {
  display: none;
}

.projDropDn {
  display: flex;
  width: 80%;
  margin: auto;
  padding: 0 10px;
  justify-content: flex-end;
}

.projDropDn button {
  display: block;
  padding: 2rem 4rem;
  border: none;
  font-size: 1.6rem;
  border-radius: 4px;
  color: #354347;
  cursor: pointer;
}

.projDropDn button:hover {
  background-color: lightgray;
  cursor: pointer;
  transition: background-color .5s ease-in-out;
}

/* Contact Section */

.contact {
  background-color: #e3d5ca;
}

.contact .row {
  justify-content: center;
}

.contact h1 {
  text-align: center;
  color: #1E3340;
}

.contact h2 {
  text-align: center;
  color: #1E3340;
}

form {
  margin: 2.4rem 0;
  min-width: 600px;
}

input,
textarea {
  display: block;
  font-size: 16px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid grey;
  border-radius: 4px;
  padding: 12px 20px;
  resize: none;
  font-family: "Source Sans Pro", sans-serif;
  color: black;
}

textarea {
  min-height: 300px;
}

label {
  margin: 1rem auto;
  display: block ;
  font-size: 20px;
  color: #354347;
}

.contact button {
  width: 100%;
  padding: 1em 1rem;
  margin: 1rem auto;
  display: block;
  background-color: #1E3340;
  border: none;
  font-size: 20px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.contact button:hover {
  background-color: #A67063;
  color: white;
  transition: .4s ease-in-out;
}



/* Footer */

footer {
  text-align: center;
  background-color: #EDEDE9;
}

footer p {
  margin: 0;
  padding: 1rem;
  color: #1E3340;
}

@media screen and (max-width: 1200px){
  .hero {
    padding-left: 0;
    padding-right: 0;
  }

  .headShot {
    border-radius: 50%;
    width: 25rem;
  }
}

@media screen and (max-width: 1024px) {
  .row, .projDropDn {
    width: 95%;
  }
}

@media screen and (max-width: 800px) {
  * {
    font-size: 55%;
  }

  section {
    padding: 40px 0;
  }

  .hero .row {
    flex-direction: column-reverse;
  }

  .heroLeft,h2,a {
    text-align: center;
  }

  .heroLeft,
  .heroRight {
    margin: 0;
  }

  .resume {
  margin: 6px auto;

  }

  .hamburger {
    display: inline-block;
    position: fixed;
    z-index: 100;
    top: 1rem;
    right: 1rem;
    padding: 4px;
    border: grey solid 1px;
    background: white;
    cursor: pointer;
    color: grey;
  }

  .closeIcon {
    display: none;
    background-color: white;
  }

  .menu {
    position: fixed;
    transform: translateY(-100%);
    transition: transform 0.2s;
    top: 0;
    left: 0%;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(211, 211, 211, .95);
    list-style: none;
    padding-top: 4rem;

  }

  .menu li a{
    color: #354347;
    font-size: 3rem;
  }

  .menu li a:active {
    color: white;
  }

  .menu li {
    text-align: center;
    padding: 0 2rem 0 0;
  }
  
  .showMenu {
    transform: translateY(0);
  }

  nav {
    flex-direction: column;
    position: relative;
  }

  nav ul {
    background-color: white;
  }

  nav ul li {
    display: block;
    padding: 2% 0;
  }

  nav ul li a {
    color: black;
  }

 .row1,
 .row2 {
    flex-direction: column;
    align-items: center;
  }

  .projDropDn {
    justify-content: center;
  }


  .projContainer {
    width: 90%;
  }

  form {
    min-width: 90%;
  }

  .projContainer:hover {
    box-shadow: none;
    background-color: transparent;
    transition: background-color .2s ease-in-out;
    transform: none;
  }

  .contact button:hover {
    background-color: #EDEDE9;
    color: black;
  }

  .contact-info-wrap {
    padding: 0 6px;
  }
}

@media screen and (max-width: 500px) {
  .logo h1,
  .logo span {
    font-size: 2.5rem;
    text-align: left;
  }

  nav {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 375px) {
  .logo h1,
  .logo span {
    font-size: 2rem;
  }

  nav, header {
    height: 60px;
  }
}